

.content-container{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: auto;
    .form-container{
        width: 40%;
       }
       
       .application-title{
           width: 20%;
           margin-top: 20px;
           margin-right: 20px;
           display: flex;
           flex-direction: column;
       }
       
       
       .menu-block{
           width: 100%;
       }
}

@media only screen and (max-width: 800px) {

.content-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-top: auto;
    align-items: center;
    .form-container{
        width: 90%;
       }
       
       .application-title{
           width: 90%;
           margin-top: 20px;
           margin-right: 20px;
           display: flex;
           flex-direction: column;
       }
       
       
       .menu-block{
           width: 100%;
       }
}
}