
.faq-container{
    margin-top: auto;
    .faq-group{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 70%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        .faq-question{
            margin: 0;
            font-size: 1.5rem;
            
        }
        .faq-answer{
            font-size: 1rem;
    
        }
    }
}
