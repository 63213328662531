.drop-menu-container{
    display:flex;
    
    position: fixed;
    max-width: 1280px;
    width: 90vw;
    
    justify-content: space-between;
    background-color: white;
    .hamburger-menu{
        display: none;
    }
    .logo-container{
       display: flex;
        overflow: hidden;
        
    
        
        .bg-logo-image{
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 150px;
            height: 100px;
        }
    }
    .regular-menu-btn{
        display: flex;
        
        justify-content:space-between;
        align-items: center;
        width: 50%;
        .menu-item{
            display:"inline-block";
        }

    }
    .menu-link{
        
        
        display: inline-block;
        text-decoration: none;
        color: black;
        
    }
    .highlighted-btns-container{
            
        display: flex;
        justify-content: space-around;
        width: 30%;
        align-items: center;
        .hightLightedBtn{
            color:white;
            background-color: black;
            padding:10px 15px;
            border-radius:15px;
            text-align: center;
            width: 100%;
            font-size: 1rem;
            word-wrap:normal
        }
    
      
    }

}






@media only screen and (max-width: 800px) {
    .drop-menu-container{
        display:flex;
        
        position:relative;
        max-width: 1280px;
        width: 100%;
        
        justify-content: center;
        background-color: white;
        flex-direction: column;
        align-self: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        .hamburger-menu{
            display: inline-block;
            position: fixed;
            top: 50px;
            right: 30px;
            z-index: 1001;
            color: white;
        }
        .logo-container{
           display: flex;
            overflow: hidden;
            
        
            
            .bg-logo-image{
                // display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 250px;
                height: 150px;
            }
        }
        .regular-menu-btn{
            display: flex;
            flex-direction: column;
            position: fixed;
            top:0;
            bottom: 0;
            justify-content:center;
            // align-items: center;
            width: 400px;
            background-color: black;
            width: 90vw;
            height: 90vh;
            margin-top: auto;
            margin-bottom: auto;
            z-index: 1000;
            .menu-item{
                display:"inline-block";
                margin-top: 2em;
                color: white;
                font-size: 1.5em;
            }
    
        }
        .menu-link{
            
            
            display: inline-block;
            text-decoration: none;
            color: black;
            
        }
        .highlighted-btns-container{
                
            display: flex;
            justify-content: space-around;
            width: 90%;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            .hightLightedBtn{
                color:white;
                background-color: black;
                padding:10px 15px;
                border-radius:15px;
                text-align: center;
                width: 100%;
                font-size: .9em;
            }
        
          
        }
    
    }
  
  }