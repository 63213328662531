.footer-container{
    display: flex;
    margin-top: auto;
    color:white;
    display:flex;
    width:100%;
    min-height:200px;
    justify-content:center;
    .footer-image{
        width:40%;
        margin-right:auto; 
        margin-left:10%;
    }
    .footer-info-container{
        display:flex;
        margin-right:auto;
        color:black;
        text-align:left; 
        margin-top:auto; 
        margin-bottom:auto;
        .footer-social-contain{
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            margin-right:20px;
            .footer-social-text{
                display:inline-block;
                margin:0; 
                padding:0
            }
            .footer-social-icons-contain{
                display: flex;
                .footer-icon{
                    width: 30px;
                    margin-right: 10px;
                }
            }
        }
        .footer-contact-container{
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            .footer-contact-text{
                margin:0;
                padding:0
            }
        }
    }
}



@media only screen and (max-width: 800px) {
    .footer-container{
        display: flex;
        flex-direction: column;
        margin-top: auto;
        color:white;
        width:100%;
        min-height:200px;
        
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        .footer-image{
            width:40%;
            margin-right:auto; 
            margin-left:10%;
            margin-left: auto;
            margin-right: auto;
        }
        .footer-info-container{
            display:flex;
            flex-direction: column;
           
            color:black;
          justify-self: center;
          margin: auto;
            justify-content: center;
            align-items: center;
            .footer-social-text{
                    
                margin-right:0;
                margin-left: 0; 
                padding:0;
                text-align: center;
            }
            .footer-social-contain{
                display:flex;
                flex-direction:column;
                justify-content:center;
                margin: 0;

                
                .footer-social-icons-contain{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .footer-icon{
                        width: 30px;
                        margin-left: auto;
                        // margin-right: 10px;
                    }
                }
            }
            .footer-contact-container{
                display:flex;
                flex-direction:column;
                justify-content:center;
                text-align: center;
                margin-top: 20px;
                .footer-contact-text{
                    margin:0;
                    padding:0
                }
            }
        }
    }
}