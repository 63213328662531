.products-container{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    width: 70%;
    height: auto;

    .product-item{
        margin-top: 50px;
        width: 45%;
        height: 400px;
        border: 1px solid black;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .product-image{
            width: 100%;
            height: 200px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .product-description-container{
            margin: auto;
            .product-title{
                font-size: 2rem;
            }
            .product-description{
                font-size: 1rem;
            }
        }
        
    }

}


@media only screen and (max-width: 800px) {
    .products-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-content:space-between;
        flex-wrap: wrap;
        width: 70%;
        height: auto;
    
        .product-item{
            margin-top: 50px;
            width: 90%;
            height: 400px;
            border: 1px solid black;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .product-image{
                width: 100%;
                height: 50%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            .product-description-container{
                margin: auto;
                padding: 20px;
                .product-title{
                    font-size: 1.5rem;
                }
                .product-description{
                    font-size: 1rem;
                }
            }
            
        }
    
    }
    
}